import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

import { images } from "../../constants";
import { FooterOverlay, Newsletter } from "../../components";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        {/* <p className="p__opensans">9 W 53rd St, New York, NY 10019, USA</p> */}
        <p className="p__opensans">contact@crestpay.com</p>
        <p className="p__opensans">+91 7061548071</p>
      </div>

      <div className="app__footer-links_logo">
        {/* <img src={images.gericht} alt="footer_logo" /> */}
        <p className="p__opensans">
          {/* &quot; */}
          Follow Us
          {/* of others.&quot; */}
        </p>
        {/* <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        /> */}

        

        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Resources</h1>
        <p className="p__opensans">FAQs</p>
        <p className="p__opensans">Customer Support</p>
        {/* <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p> */}
      </div>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans">2024 CrestPay. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
