import React from 'react';
import './TermsAndConditions.css'; // Create a separate CSS file for styling

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          CrestPay, offers a Buy Now, Pay Later (BNPL) solution for B2B payments, aimed at addressing delayed payments in the MSME sector in India.
        </p>
      </section>

      <section>
        <h2>2. Service Description</h2>
        <p>
          CrestPay provides a platform where sellers can offer BNPL options to buyers through a hosted invoice page. This integration facilitates immediate payments to sellers and flexible financing options for buyers, enhancing cash flow management and operational efficiency.
        </p>
      </section>

      <section>
        <h2>3. User Responsibilities</h2>
        <p>
          Users of CrestPay agree to provide accurate information during registration and payment processes. Sellers are responsible for honoring the terms of sale and delivery agreed upon with buyers. Buyers are responsible for adhering to the agreed BNPL terms and making payments within the specified timelines.
        </p>
      </section>

      <section>
        <h2>4. Payment Terms</h2>
        <p>
          Payments made through CrestPay are subject to the agreed BNPL terms between sellers and buyers. Sellers receive payments promptly upon transaction completion, while buyers have the option to repay through structured installments as per the BNPL agreement.
        </p>
      </section>

      <section>
        <h2>5. Fees and Charges</h2>
        <p>
          CrestPay charges transaction fees to sellers and may levy late payment charges on buyers for missed deadlines. Detailed fee structures are outlined in the platform's pricing policy and may be updated from time to time with prior notice.
        </p>
      </section>

      <section>
        <h2>6. Data Security</h2>
        <p>
          CrestPay prioritizes data security and uses industry-standard encryption methods to safeguard user information. Users are encouraged to maintain the confidentiality of their login credentials and report any unauthorized access promptly.
        </p>
      </section>

      <section>
        <h2>7. Termination of Services</h2>
        <p>
          CrestPay reserves the right to terminate or suspend services to users found violating the terms and conditions or engaging in fraudulent activities. Users may also terminate their account with CrestPay by providing notice as per the cancellation policy.
        </p>
      </section>

      <section>
        <h2>8. Governing Law</h2>
        <p>
          These terms and conditions are governed by the laws of Indian State Government.
        </p>
      </section>

      <section>
        <h2>9. Contact Information</h2>
        <p>
          For queries or assistance regarding CrestPay services, users can contact at contact@crestpay.in.
        </p>
      </section>

      <section>
        <h2>10. Changes to Terms</h2>
        <p>
          CrestPay reserves the right to modify these terms and conditions at any time. Users will be notified of significant changes via email or through the platform. Continued use of CrestPay after such modifications constitutes acceptance of the updated terms.
        </p>
      </section>

      <p className="disclaimer">
        By using CrestPay, users agree to abide by these terms and conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
