import React from "react";

import {
  AboutUs,
  Footer,
  Header,
} from "./container";
import { Navbar } from "./components";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Navbar from './components/Navbar/Navbar';
import TermsAndConditions from './container/TermsAndConditions/TermsAndConditions';


const Home = () => {
  return (
    <div>
    <Header />
    <AboutUs />
  </div>
  );
};

// const Terms= () => {
//   return (
//     <div>
//        <Navbar />
//        <TermsAndConditions />
//        <Footer />
//     </div>
//   )
// }

// const App = () => (
//   <div>
//     <Navbar />
//     <Header />
//     <AboutUs />
//     {/* <SpecialMenu /> */}
//     {/* <Chef />
//     <Intro />
//     <Laurels />
//     <Gallery /> */}
//     {/* <FindUs /> */}
//     {/* <TermsAndConditions/> */}
//     <Footer />
//   </div>
// );

// // const App = () => {
// //   <div>
// //     {/* // <Router> */}
// //             <Navbar />

// //       {/* <Routes>
// //         <Route path="/" component={Home} />
// //         <Route path="/terms" component={Home} />
// //       </Routes> */}
// //     {/* // </Router> */}
// //     </div>
// // };

// export default App;


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          {/* Other routes */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

