// import React, { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { MdOutlineRestaurantMenu } from "react-icons/md";

// import images from "../../constants/images";
// import "./Navbar.css";

// const Navbar = () => {
//   const [toggleMenu, setToggleMenu] = useState(false);

//   return (
//     <nav className="app__navbar">
//       <div className="app__navbar-logo">
//         <img src={images.logo1} alt="app log" />
//       </div>
//       <ul className="app__navbar-links">
//         <li className="p__opensans">
//           <a href="#products">Products</a>
//         </li>
//         <li className="p__opensans">
//           <a href="#solutions">Solutions</a>
//         </li>
//         <li className="p__opensans">
//           <a href="#integrations">Integration</a>
//         </li>
//         <li className="p__opensans">
//           <a href="#resources">Resources</a>
//         </li>
//         <li className="p__opensans">
//           <a href="#company">Company</a>
//         </li>
//       </ul>
//       <div className="app__navbar-login">
//         <a href="#login" className="p__opensans">
//           Log In
//         </a>
//         <div />
//         <a href="/" className="p__opensans">
//           Register
//         </a>
//       </div>
//       <div className="app__navbar-smallscreen">
//         <GiHamburgerMenu
//           color="#fff"
//           fontSize={27}
//           onClick={() => setToggleMenu(true)}
//         />

//         {toggleMenu && (
//           <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
//             <MdOutlineRestaurantMenu
//               fontSize={27}
//               className="overlay__close"
//               onClick={() => setToggleMenu(false)}
//             />
//             <ul className="app__navbar-smallscreen_links">
//               <li className="p__opensans">
//                 <a href="#products" onClick={() => setToggleMenu(false)}>
//                   Home
//                 </a>
//               </li>
//               <li className="p__opensans">
//                 <a href="#solutions" onClick={() => setToggleMenu(false)}>
//                   About
//                 </a>
//               </li>
//               <li className="p__opensans">
//                 <a href="#integrations" onClick={() => setToggleMenu(false)}>
//                   Menu
//                 </a>
//               </li>
//               <li className="p__opensans">
//                 <a href="#resources" onClick={() => setToggleMenu(false)}>
//                   Awards
//                 </a>
//               </li>
//               <li className="p__opensans">
//                 <a href="#company" onClick={() => setToggleMenu(false)}>
//                   Contact
//                 </a>
//               </li>
//             </ul>
//           </div>
//         )}
//       </div>
//     </nav>
//   );
// };
// export default Navbar;


import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo1} alt="app logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="/">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#solutions">Solutions</a>
        </li>
        <li className="p__opensans">
          <a href="#integrations">Integration</a>
        </li>
        <li className="p__opensans">
          <a href="#resources">Resources</a>
        </li>
        <li className="p__opensans">
          <Link to="/terms">Company</Link>
        </li>
      </ul>
      <div className="app__navbar-login">
        <a href="#login" className="p__opensans">
          Log In
        </a>
        <div />
        <a href="/" className="p__opensans">
          Register
        </a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a href="#products" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li className="p__opensans">
                <a href="#solutions" onClick={() => setToggleMenu(false)}>
                  About
                </a>
              </li>
              <li className="p__opensans">
                <a href="#integrations" onClick={() => setToggleMenu(false)}>
                  Menu
                </a>
              </li>
              <li className="p__opensans">
                <a href="#resources" onClick={() => setToggleMenu(false)}>
                  Awards
                </a>
              </li>
              <li className="p__opensans">
                <Link to="/terms" onClick={() => setToggleMenu(false)}>
                  Company
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

